html, body, #root {
  height: 100%;
  font-family: Verdana, sans-serif;
}

fieldset, .ant-form fieldset {
  margin-top: 10px;

  > legend {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 11px;
    line-height: inherit;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
  }
}

.ant-card, .ant-tabs-card {
  .ant-tabs-nav {
    z-index: 1;
  }

  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.08), 0 3px 6px 0 rgba(0, 0, 0, 0.06), 0 5px 12px 4px rgba(0, 0, 0, 0.045);
}

.ant-card.inactive, .ant-tabs-card.inactive {
  .ant-card-body {
    opacity: 0.5;
  }

  background-color: #f0f0f0;
}

.ant-btn:not(.ant-btn-link, .ant-btn-text, [disabled]) {
  box-shadow: -1px -1px 1px 1px inset hsl(0, 0%, 95%), 4px 4px 4px -2px hsl(0, 0%, 90%);

  &:not(.ant-btn-background-ghost) {
    &.ant-btn-primary {
      box-shadow: -1px -1px 1px 1px inset darken(@primary-color, 4%), 4px 4px 4px -2px hsl(0, 0%, 90%);
      border-color: darken(@primary-color, 4%);

      &:hover {
        box-shadow: -1px -1px 1px 1px inset @primary-color, 4px 4px 4px -2px hsl(0, 0%, 90%);
        border-color: @primary-color;
      }
    }

    &.ant-btn-danger {
      box-shadow: -1px -1px 1px 1px inset darken(#ff4d4f, 4%), 4px 4px 4px -2px hsl(0, 0%, 90%);
      border-color: darken(#ff4d4f, 4%);

      &:hover {
        box-shadow: -1px -1px 1px 1px inset #ff4d4f, 4px 4px 4px -2px hsl(0, 0%, 90%);
        border-color: #ff4d4f
      }
    }
  }
}

.ant-btn.ant-btn-link {
  padding: 0;
}

.ant-form-item {
  margin-bottom: 8px;
}

.ant-picker-input > input {
  width: 140px;
}

.ant-input-number-input {
  text-align: right;
}

.ant-input-number-sm input {
  padding-right: 28px;
}

.ant-input-number {
  width: 100%;
}

.ant-popover-buttons {
  display: flex;
  flex-direction: row-reverse;
}

.ant-tabs-dropdown-menu {
  max-height: 400px;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  padding: 0 20px;
}

.ant-modal-body {
  max-height: calc(100vh - 256px);
  overflow-y: auto;
}

.monospace {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

.ws-pre {
  white-space: pre;
}

.ws-pre-line {
  white-space: pre-line;
}

.grow {
  flex-grow: 1;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.primary {
  color: @primary-color;
}

.info {
  color: #1890ff;
}

.success {
  color: #52c41a;
}

.warning {
  color: #faad14;
}

.error {
  color: #ff4d4f;
}

.inactive {
  color: #999999;
}

.background-primary {
  background-color: @primary-color;
  color: white;
}

.background-info {
  background-color: #1890ff;
  color: white;
}

.background-success {
  background-color: #52c41a;
  color: white;
}

.background-warning {
  background-color: #faad14;
  color: white;
}

.background-error {
  background-color: #ff4d4f;
  color: white;
}

.background-inactive {
  background-color: #999999;
  color: white;
}

.ant-alert-inactive {
  background-color: #f0f0f0;
}

.select-dark {
  background-color: #262626;

  > .ant-select-selector {
    background-color: #262626 !important;
    color: rgba(255, 255, 255, 0.65);
  }

  > .ant-select-arrow {
    color: rgba(255, 255, 255, 0.65);
  }
}

@primary-color: #009edb;@layout-header-background: #262626;@font-size-base: 11px;@menu-inline-toplevel-item-height: 32px;@menu-item-height: 32px;@menu-item-group-height: 32px;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 2px;@menu-item-padding-horizontal: 4px;@menu-horizontal-line-height: 32px;