@font-face {
  font-family: 'Effra';
  src: url(Effra_Lt.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Effra';
  src: url(Effra_Rg.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Effra';
  src: url(Effra_Md.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Effra';
  src: url(Effra_Bd.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Effra';
  src: url(Effra_Md.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Effra';
  src: url(Effra_LtIt.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Effra';
  src: url(Effra_It.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Effra';
  src: url(Effra_MdIt.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Effra';
  src: url(Effra_BdIt.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Effra';
  src: url(Effra_MdIt.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

@primary-color: #009edb;@layout-header-background: #262626;@font-size-base: 11px;@menu-inline-toplevel-item-height: 32px;@menu-item-height: 32px;@menu-item-group-height: 32px;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 2px;@menu-item-padding-horizontal: 4px;@menu-horizontal-line-height: 32px;